import ReactDOM from "react-dom";
import React from "react";

import "./Modal.css";

export default function Modal({ open, children, onClose }) {
  if (!open) return null;
  return ReactDOM.createPortal(
    <>
      <div className="overlay-style" onClick={onClose} />
      <div className="modal-styles" data-keyboard="true">
        {children}
      </div>
    </>,
    document.getElementById("portal")
  );
}
