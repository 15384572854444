import AxiosService from "./AxiosService";
import Constants from "../util/constantStrings";
const BASE_URL = `${process.env.REACT_APP_API_URL}/api/Graph`;
const GraphService = {
    getServiceData(payload){
        const config = {
            url: `${BASE_URL}/ServiceData?appId=${Constants.APP_ID}&dateFilter=${payload.date}`,
        }
        return AxiosService.get(config.url,{})
    },
    getServiceDataById(payload){
        const config = {
            url: `${BASE_URL}/ServiceDataById?appId=${Constants.APP_ID}&providerId=${payload.id}&dateFilter=${payload.date}`,
        }
        return AxiosService.get(config.url,{})
    },
    getTowTruckAgentData(){
        const config = {
            url: `${BASE_URL}/TowTruckAgentData?appId=${Constants.APP_ID}`,
        }
        return AxiosService.get(config.url,{})
    },
    getTowTruckAgentDataById(id){
        const config = {
            url: `${BASE_URL}/TowTruckAgentDataById?appId=${Constants.APP_ID}&providerId=${id}`,
        }
        return AxiosService.get(config.url,{})
    },
}
export default GraphService;
