import {useRecoilState, useSetRecoilState, useResetRecoilState} from "recoil";

import {history} from "_helpers/history";
import { useFetchWrapper } from "_helpers/FetchWrapper";
import {authAtom, usersAtom, userAtom} from "state";
import JwtService from "../services/JwtService";
import UserService from "../services/UserService";
export {useUserActions};
const LOGIN_TYPE = "Dashboard";
function useUserActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/Users`;
  const fetchWrapper = useFetchWrapper();
  const [auth, setAuth] = useRecoilState(authAtom);
  const setUsers = useSetRecoilState(usersAtom);
  const setUser = useSetRecoilState(userAtom);

  return {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    resetUsers: useResetRecoilState(usersAtom),
    resetUser: useResetRecoilState(userAtom),
  };

  function login({username, password}) {
    return fetchWrapper
    .post(`${baseUrl}/Authenticate`, {username, password, type:LOGIN_TYPE})
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        UserService.setUser(user)
        JwtService.setToken(user.token)
        setAuth(user);
        
        // get return url from location state or default to home page
        const {from} = history.location.state || {from: {pathname: "/"}};
        history.push(from);
      });
  }

  function logout() {
    // remove user from local storage, set auth state to null and redirect to login page
    UserService.destroyUser()
    JwtService.destroyToken()
    setAuth(null);
    history.push("/account/login");
  }

  function register(user) {
    return fetchWrapper.post(`${baseUrl}/register`, user);
  }

  function getAll() {
    return fetchWrapper.get(baseUrl).then(setUsers);
  }

  function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`).then(setUser);
  }

  function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params).then((x) => {
      // update stored user if the logged in user updated their own record
      if (id === auth?.id) {
        // update local storage
        const user = {...auth, ...params};
        UserService.setUser(JSON.stringify(user))

        // update auth user in recoil state
        setAuth(user);
      }
      return x;
    });
  }

  // prefixed with underscored because delete is a reserved word in javascript
  function _delete(id) {
    setUsers((users) =>
      users.map((x) => {
        // add isDeleting prop to user being deleted
        if (x.id === id) return {...x, isDeleting: true};

        return x;
      })
    );

    return fetchWrapper.delete(`${baseUrl}/${id}`).then(() => {
      // remove user from list after deleting
      setUsers((users) => users.filter((x) => x.id !== id));

      // auto logout if the logged in user deleted their own record
      if (id === auth?.id) {
        logout();
      }
    });
  }
}
