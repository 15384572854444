import React from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import DateFormatUtil from "../util/utilityFunctions/DateFormatUtil";

function ImageViewer({serviceFolderList}) {
    function showGalleryItem(folderItem,index){
        return (
            <div className="col-sm-4" key={index}>
                <Item
                    original={`data:image/png;base64,${folderItem.imageData}`}
                    thumbnail={`data:image/png;base64,${folderItem.imageData}`}
                    width="768"
                    height="1024"
                >
                    {({ ref, open }) => (
                        <>
                        <img
                            ref={ref}
                            onClick={open}
                            src={`data:image/png;base64,${folderItem.imageData}`}
                            className="img-col"
                        />
                        <p>{DateFormatUtil.getDottedDMYByHourFormatDate(folderItem.uploadDate)}</p>
                        </>
                    )}
                </Item>
            </div>
        )
    }
  return (
    <>
      <Gallery initialZoomIn="true">
        {(serviceFolderList ?? []).map((folderItem, index) => showGalleryItem(folderItem, index))}
      </Gallery>
    </>
  );
}

export default ImageViewer;
