export const checkIsEmptyString = (value) => {
    if(typeof value === 'string' && value.trim() !== '') {
        return false;
    } else {
        return true;
    }
}

export const checkIsNullOrUndefined = (value) =>{
    if (typeof value !== 'undefined' && value) {
       return false;
    } else return true;
}
export const nullSafe = (value) => {
    return value ?? ""
}
export const safeArrayCheck = (array, index) =>{
    return array && array.length > 0 && index >= 0 && array.length > index;
}
export const safeArrayElementAccess =(array, index, defaultValue) =>{
    return safeArrayCheck(array, index) ? array[index] : defaultValue;
}
export const getLastElementOfArray =(array, defaultValue) => {
    return safeArrayCheck(array, 0) ? array[array.length - 1] : defaultValue;
}
export default {nullSafe, checkIsNullOrUndefined, checkIsEmptyString, safeArrayCheck, safeArrayElementAccess, getLastElementOfArray};
