import AxiosService from "./AxiosService";
import Constants from "../util/constantStrings";
const BASE_URL = `${process.env.REACT_APP_API_URL}/api/Customer`;

const CustomerService = {
    getCustomerLocationByServiceId(id) {
        const config = {
            url: `${BASE_URL}/Location?appId=${Constants.APP_ID}&serviceId=${id}`,
        }
        return AxiosService.get(config.url,{})
    },
    getCustomerLocationByServiceGuid(guid){
        const config = {
            url: `${BASE_URL}/LocationbyGuid?appId=${Constants.APP_ID}&serviceGuid=${guid}`,
        }
        return AxiosService.get(config.url,{})
    },
    postCustomerLocationInfo(params) {
        const config = {
            url: `${BASE_URL}/Location?appId=${Constants.APP_ID}`,
            data: params
        }
        return AxiosService.post(config.url,config.data)
    }
}
export default CustomerService;