import React, {useEffect, useState} from "react";
import ProviderService from "../../../services/ProviderService";
import "./IdentifyPlateComponent.css";
import TowTruckService from "../../../services/TowTruckService";
import {useFormik} from "formik";
import ErrorService from "../../../services/ErrorService";
import {errorToast, successToast} from "../../../_helpers/ToastMessages";
import Constants from "../../../util/constantStrings";
import {useRecoilValue} from "recoil";
import { authAtom } from "state";
function IdentifyPlateComponent(){
    const auth = useRecoilValue(authAtom);
    const [providerList, setProviderList] = useState([])
    const [toastMessage, setToastMessage] = useState("");
    const [showSuccessToastMessage, setSuccessShowToastMessage] = useState(false);
    const [showErrorToastMessage, setErrorShowToastMessage] = useState(false);
    function saveNewPlateAndImei(payload){
        TowTruckService.postTowTruckAgents(payload).then(()=> {
            setToastMessage(Constants.PLATE_IDENTIFY_SUCCESS_MESSAGE);
            setSuccessShowToastMessage(true);
            setInterval(() => {
                setSuccessShowToastMessage(false);
            }, 4000)
        }).catch(error => {
            setToastMessage(error.response.data.message ?? Constants.PLATE_IDENTIFY_ERROR_MESSAGE);
            setErrorShowToastMessage(true);
            setInterval(() => {
                setErrorShowToastMessage(false);
            }, 4000)
            ErrorService.errorConsole(error)
        });
    }
    const formik = useFormik({
        initialValues: {
            plate: "",
            imei: "",
            provider: "",
            mobileNumber: ""
        },
        onSubmit: (values) => {
                const payload = {
                    plateNumber: values.plate,
                    imei: values.imei,
                    providerId: Number(values.provider),
                    mobileNumber: values.mobileNumber
                }
            saveNewPlateAndImei(payload);
        },
    });
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.role === Constants.PROVIDER) {
            ProviderService.getProviderList(0).then(response => {
                const filteredProviderList = response.data.data.providerListItemDtos.filter(item => item.pid === user.id)
                setProviderList(response.data.data.providerListItemDtos)
            }).catch(error => ErrorService.errorConsole(error))
        } else {
            ProviderService.getProviderList(0).then(response => setProviderList(response.data.data.providerListItemDtos)).catch(error => ErrorService.errorConsole(error))
        }
    }, [auth])
    return (
            <div className="row mt-lg-4">
                {showSuccessToastMessage && successToast(toastMessage)}
                {showErrorToastMessage && errorToast(toastMessage)}
                <div className="col-12">
                    <div className="border-white rounded shadow bg-white rounded padding-20 container container-height">
                        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                            <div className="form-group row mb-5">
                                <label htmlFor="provider" className="col-sm-2 col-form-label">Tedarikçi</label>
                                <div className="col-sm-10">
                                    <select
                                        id="provider"
                                        name="provider"
                                        className="form-select"
                                        onChange={formik.handleChange}
                                        value={formik.values.provider}
                                    >
                                        <option selected>Select</option>
                                        {providerList.map(providerItem => {
                                            return <option value={providerItem.pid}>{providerItem.pname}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row mb-5">
                                <label htmlFor="plate" className="col-sm-2 col-form-label">Plaka</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="plate" name="plate" placeholder="Plaka" onChange={formik.handleChange}
                                           value={formik.values.plate}/>
                                </div>
                            </div>
                            <div className="form-group row mb-5">
                                <label htmlFor="mobileNumber" className="col-sm-2 col-form-label">Telefon Numarası</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="mobileNumber" name="mobileNumber" placeholder="Telefon Numarası"  onChange={formik.handleChange}
                                           value={formik.values.mobileNumber}/>
                                </div>
                            </div>
                            <div className="form-group row mb-5">
                                <label htmlFor="imei" className="col-sm-2 col-form-label">Cihaz Id</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="imei" name="imei" placeholder="Cihaz Id"  onChange={formik.handleChange}
                                           value={formik.values.imei}/>
                                </div>
                            </div>
                            <div className="form-group row mb-5 float-end">
                                <button type="submit" className="btn btn-primary">Kaydet</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    )
}
export { IdentifyPlateComponent };