import { useSetRecoilState, useResetRecoilState } from "recoil";
import TowTruckService from "services/TowTruckService";
import {
  IncompleteServicesAtom,
  PlateAtom,
  CurrentServicesAtom, ServiceStatusInfoAtom, ServiceFolderListAtom, ServiceDetailAtom,
  ServicesAtom
} from "state";
import ServiceService from "../services/ServiceService";
import ErrorService from "../services/ErrorService";
export { useServicesActions };

function useServicesActions() {
  const setServices = useSetRecoilState(ServicesAtom);

  const setCurrentServices = useSetRecoilState(CurrentServicesAtom);

  const setCurrentServiceStatus = useSetRecoilState(CurrentServicesAtom);

  const setPlates = useSetRecoilState(PlateAtom);

  const setServiceStatusInfoList = useSetRecoilState(ServiceStatusInfoAtom)

  const setServiceFolderList = useSetRecoilState(ServiceFolderListAtom)

  const setServiceDetail = useSetRecoilState(ServiceDetailAtom)

  return {
    getServices,
    getPlates,
    getCurrentServices,
    getCurrentServiceStatus,
    getServiceStatusInfoList,
    getServiceFolderList,
    getServiceDetailById,
    resetservices: useResetRecoilState(IncompleteServicesAtom),
  };

  function getServices(page){
    ServiceService.getServiceList(page).then(response => {
      setServices(response.data.data.services)
    }).catch(error => ErrorService.errorConsole(error))
  }

  function getPlates(page) {
    TowTruckService.getProviderCarPlateList({page}).then(response => {
      setPlates(response.data.data.list)})
    .catch(error => ErrorService.errorConsole(error))
  }

  function getCurrentServices(services) {
    setCurrentServices(services);
  }

  function getCurrentServiceStatus(services) {
    setCurrentServiceStatus(services.Durum);
  }

  function getServiceStatusInfoList(serviceId){
    ServiceService.getServiceStatusByServiceId(serviceId).then(response => {
      setServiceStatusInfoList(response.data.data.serviceStatusListDto)})
        .catch(error => ErrorService.errorConsole(error))
  }

  function getServiceFolderList(params) {
    const payload = {
      serviceId: params.serviceId,
      geoStatusId: params.geoStatusId,
      page: params.page
    }
    ServiceService.getServiceFolderByServiceId(payload).then(response => {
      setServiceFolderList(response.data.data.photos)})
        .catch(error => ErrorService.errorConsole(error))
  }

  function getServiceDetailById(serviceId) {
    ServiceService.getServiceDetailById(serviceId).then(response => {
      setServiceDetail(response.data.data)
    }).catch(error => ErrorService.errorConsole(error))
  }
}
