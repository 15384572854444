import axios from "axios";
const AxiosService = {
    post(url, data, body) {
        return axios({
            method: "post",
            url: `${url}`,
            data: data,
            body: body
        });
    },
    get(url, data, params) {
        return axios({
            method: "get",
            url: `${url}`,
            data: data,
            params: params
        });
    },
    put(url, data) {
        return axios({
            method: "put",
            url: `${url}`,
            data: data,
        });
    },
    delete(url, data) {
        return axios({
            method: "delete",
            url: `${url}`,
            data: data
        });
    }
}
export default AxiosService