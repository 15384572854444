import moment from "moment";
import Constants from "../constantStrings";
import Util, {checkIsNullOrUndefined} from "./Util";

const getDottedDMYByHourFormatDate = (date) => {
    return Util.checkIsNullOrUndefined(date) ? "" : moment(date).format(Constants.DATE_FORMAT_WITH_HOUR_HM);
}
const isDateValid = (date) => {
    if(!checkIsNullOrUndefined(date)) {
        return moment(date).isValid();
    } else return false;
}
export default {getDottedDMYByHourFormatDate, isDateValid}