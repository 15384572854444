import React from "react";
import "../_components/pages/tow-truck-location/TowTruckLocationComponent.css"
function ActionNotification({message, permission, denied}){
    return (
        <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-body">
              {message}
                <div className="mt-2 pt-2 border-top">
                    <button type="button" className="btn btn-sm btn-danger" onClick={()=> denied()}>İzin Verme</button>
                    <button type="button" className="btn btn-sm btn-primary notification-button-primary" onClick={()=> permission()}>İzin Ver</button>
                </div>
            </div>
        </div>
    )
}
export default ActionNotification