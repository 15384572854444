import { GoogleMaps } from "_helpers";
import {useEffect, useState} from "react";
import TowTruckService from "../../../services/TowTruckService";
import {LoadScript} from "@react-google-maps/api";
import ErrorService from "../../../services/ErrorService";
import RoleUtil from "../../../util/utilityFunctions/RoleUtil";
import Constants from "../../../util/constantStrings";
const Map = (props) => {
  const API_KEY = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
  const [towTruckLocationList, setTowTruckLocationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
    function setTowTruckLocation(list) {
        const locationList = list.map(serviceStatusInfoItem => {
            return {
                lat: Number(serviceStatusInfoItem.latitude),
                lng: Number(serviceStatusInfoItem.longitude),
                statusId: Constants.DEFAULT_TOW_TRUCK_LOCATION_STATUS_ID,
                plateNumber: serviceStatusInfoItem.plateNumber
            }
        })
        setTowTruckLocationList(locationList);
    }
    function getTowTruckLocation(){
      if(RoleUtil.isSupervisorAdmin()) {
          TowTruckService.getAllTowTruckLocation({page:0}).then(response => {
              setTowTruckLocation(response.data.data.allTowTruckAgents);
          }).catch(error => ErrorService.errorConsole(error))
      }else {
          TowTruckService.getTowTruckLocationInfoByProviderId({page:0}).then(response => {
              setTowTruckLocation(response.data.data.towTruckAgents);
          }).catch(error => ErrorService.errorConsole(error))
      }
    }
    useEffect(()=> {
        getTowTruckLocation()
    },[])
    useEffect(()=> {
      setInterval(()=>{
          getTowTruckLocation()
      },180000)
  },[])

  return (
      <div className="card m-5 p-4">
        <div className="card-body">
        <LoadScript id={"google-map-script"} googleMapsApiKey={API_KEY}>
        <GoogleMaps
            towTruckLocationList={towTruckLocationList}
            showTruckLocation={true}
            isLoading={isLoading}
            setIsLoading={(state) => setIsLoading(state)}
            showDistanceMatrix={false}
        />
        </LoadScript>
      </div>
    </div>
  );
};
export { Map };
