import React, { Component } from 'react';

//import { Card } from 'bootstrap/Card';


export class PrivacyPolicy extends Component {
    static displayName = PrivacyPolicy.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="mt-3">
                    <div>
                        <h5>GIZLILIK POLITIKASI</h5>
                    </div>
                    <div>
                        <p>
                            INTER PARTNER ASSISTANCE YARDIM VE DESTEK HİZMETLERİ TİCARET ANONİM ŞİRKETİ ("IPA") gizliliğinize saygı duymakta ve tüm Kişisel Verilerin başta 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ikincil olarak Genel Veri Koruma Yönetmeliği (No. 2016/679) ("GDPR") olmak üzere Türk Kişisel Verilerin Korunması Mevzuatına uygun olarak işlenmesini sağlamaktadır.
                            <br />
                        </p>
                        <br />
                        <p>
                            Aşağıda yer alan açıklamaları, 6698 sayılı Kişisel Verilerin Korunması Kanunu'nun ('KVKK') 10. maddesi ve ilgili mevzuat hükümlerinden doğan aydınlatma yükümlülüğünü yerine getirmek amacıyla siz değerli veri sahiplerimizin bilgisine sunarız.
                            <br />
                        </p>
                        <br />
                        <p>
                            Bu geolocation.ipaistanbul.com web sitesi (bundan böyle "Web Sitesi" olarak anılacaktır), AXA Partners Grubu şirketleri olan IPA ve AXA Partners Türkiye tarafından sunulan çeşitli hizmetleri Inter Partner Assistance Yardım ve Destek Hiz.Tic. A.Ş. TARAFINDAN SUNULMAKTADIR. Bu Gizlilik Politikası (bundan sonra "Politika" olarak anılacaktır), yalnızca Web Sitesi aracılığıyla veya "Bize Ulaşın" bölümü aracılığıyla genel bilgi almak için çağrı merkezimiz aracılığıyla bizimle iletişime geçtiğinizde Kişisel Verilerinizin işlenmesi için geçerlidir.
                            <br />
                        </p>
                        <p>
                            Ayrıca, aşağıdaki hizmetler için bir müşteri (veya ortaklarımızdan birinin müşterisi) olduğunuzda Kişisel Verilerinizin işlenmesine ilişkin daha spesifik bilgilere yönlendiren bağlantılar bulacaksınız:
                            <br />
                        </p>
                        <p>
                            	Otomobil yardımı, seyahat yardımı, ev yardımı, sağlık yardımı, Inter Partner Assistance Yardım ve Destek Hiz. Tic. A.Ş. TARAFINDAN SAĞLANMAKTADIR. (IPA) TARAFINDAN SAĞLANMAKTADIR;
                            <br />
                        </p>
                        <p>
                            Kişisel Veri, tanımlanmış veya tanımlanabilir bir gerçek kişiye (bir veri sahibi) ilişkin her türlü bilgi anlamına gelir; tanımlanabilir bir gerçek kişi, doğrudan veya dolaylı olarak, özellikle bir isim, kimlik numarası, konum verileri, çevrimiçi bir tanımlayıcı veya söz konusu gerçek kişinin fiziksel, fizyolojik, genetik, zihinsel, ekonomik, kültürel veya sosyal kimliğine özgü bir veya daha fazla faktör gibi bir tanımlayıcıya atıfta bulunularak tanımlanabilen kişidir.
                            <br />
                        </p>
                        <p>
                            Bu Politika bağlamında, bir veri sahibi olarak, bundan böyle "siz" olarak anılacaksınız.
                            <br />
                        </p>
                        <p>
                            <b>1.	Biz kimiz? Veri sorumlusu kimdir?</b><br />
                            Bu Web Sitesindeki özel form aracılığıyla bizimle iletişime geçtiğinizde, Kişisel Verilerinizin Veri Denetleyicisi olarak hareket eder (yani, Verilerinizin işlenme amaçlarını ve araçlarını belirler), bu talebin alıcısı, yani:
                            <br />
                        </p>
                        <p>
                            <b>Inter Partner Assistance Yardım Ve Destek Hizmetleri Ticaret Anonim Şirketi ("IPA")</b><br />
                            <b>E-posta: interpartnerassistance@hs03.kep.tr, info@ipaistanbul.com</b><br />
                            <b>Muhatap: Yamanevler Mah. Site Yolu Cad. No:5 Kat:5 İç Kapı No:13-14 Ümraniye/İstanbul</b><br />
                            <br />
                        </p>
                        <p>
                            Bu Web Sitesinde gerçekleştirilen Kişisel Verilerin işlenmesi için Veri Kontrolörü bundan böyle "biz" olarak anılacaktır.
                            <br />
                        </p>
                        <p>
                            IPA tarafından yürütülen tüm faaliyetler için, bir müşteri olarak Kişisel Verilerinizin işlenmesine ilişkin bilgiler, sigorta poliçenizin genel şartları gibi aboneliğiniz üzerine size sağlanan belgelerde de belirtilmektedir.
                            <br />
                        </p>
                        <p>
                            <b>2.	Kişisel Verilerinizin sağlanması zorunlu mu?</b><br />
                            Kişisel Verilerinizin bize sağlanmasının zorunlu olup olmadığı, bu tür verilerin toplanması sırasında size belirtilecektir (örneğin, toplama formunda bir yıldız işareti ile). Zorunlu olarak tanımlanan Kişisel Verileri sağlamazsanız, sizinle düzgün bir şekilde etkileşime giremeyebiliriz.
                            <br />
                        </p>
                        <p>
                            <b>3.	 Kişisel Verileriniz ne şekilde ve hangi amaçlarla işlenmektedir?</b><br />
                            IPA, web sitemiz ve çağrı merkezimiz aracılığıyla elde ettiği kimlik verileri (ad, soyad, TC kimlik numarası, doğum tarihi) ve iletişim verileri (telefon, adres, e-posta), poliçe bilgileri, araç bilgilerini ilgili mevzuat ve hukuka uygun olarak yapılan sözleşmeler kapsamında aşağıdaki amaçlarla işlemektedir:
                            <br />
                        </p>
                        <p>
                            	Her türlü ürün ve hizmet taleplerini yerine getirmek,<br />
                            	Bilgi vermek,<br />
                            	Talepte bulunan kişinin bilgilerini tespit etmek,<br />
                            	Talebe esas teşkil edecek tüm kayıt ve belgeleri elektronik veya kağıt ortamında düzenlemek;<br />
                            	Mevzuat ve sözleşmeler çerçevesinde öngörülen bilgi saklama, raporlama ve bilgilendirme yükümlülüklerine uymak;<br />
                            	Talep edilen diğer ürünleri/hizmetleri sağlayabilmek ve akdettiğiniz sözleşmenin gerekliliklerini yerine getirebilmek için.<br />
                            	İletişim formu aracılığıyla bize göndermiş olabileceğiniz herhangi bir bilgi talebine yanıt vermek. <br />
                            <br />
                        </p>
                        <p>
                            Kişisel verilerinizin işlenmesi, iletişim formunda verdiğiniz onaya dayanmaktadır.
                            <br />
                        </p>
                        <p>
                            <b>4.	Kişisel Verileriniz üzerindeki haklarınız nelerdir?</b><br />
                            <br />
                        </p>
                        <p>
                            Bu kapsamda kişisel veri sahibi olarak aşağıdaki haklara sahipsiniz;<br />
                            	Kişisel verilerinizin işlenip işlenmediğini öğrenme,<br />
                            	Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,<br />
                            	Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,<br />
                            	Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,<br />
                            	Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,<br />
                            	Kişisel verilerinizin silinmesini veya yok edilmesini talep edebilirsiniz,<br />
                            	Kişisel verilerinizin düzeltilmesi, silinmesi ya da yok edilmesi halinde bu işlemlerin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,<br />
                            	İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,<br />
                            	Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme,<br />
                            <br />
                        </p>
                        <p>
                            Bu kapsamda iletilen talepler, IPA tarafından en geç otuz gün içinde ücretsiz olarak sonuçlandırılacaktır. Ancak, Kişisel Verileri Koruma Kurulunca bir ücret öngörülmesi halinde, IPA tarafından belirlenen tarifedeki ücret alınabilir.
                            <br />
                        </p>
                        <p>
                            <b>5.	Kişisel verilerinize ilişkin haklarınızı 6698 sayılı Kanun kapsamında aşağıdaki yöntemleri kullanarak gerçekleştirebilirsiniz;</b><br />
                            <br />
                        </p>
                        <p>
                            	İletişim Bilgileri;<br />
                            <b>Veri Sorumlusu:</b> Inter Partner Assistance Yardım Ve Destek Hizmetleri Ticaret Anonim Şirketi<br />
                            <b>E-posta:</b> interpartnerassistance@hs03.kep.tr, info@ipaistanbul.com<br />
                            <b>Muhatap:</b> Yamanevler Mah. Site Yolu Cad. No:5 Kat:5 İç Kapı No:13-14 Ümraniye/İstanbul<br />
                            Otuz günlük yasal hakkınızın gereği gibi yerine getirilebilmesi için kargo ile gönderilen başvurularınızın noter tasdikli veya iadeli taahhütlü olması gerekmektedir. Bu kapsamdaki başvurular tarafımızca yapılacak kimlik doğrulamasını müteakip kabul edilecek olup, ilgili kişilere yasal süreler içerisinde yazılı veya elektronik ortamda cevap verilecektir.<br />
                            Kişisel Verileriniz, talebin alınmasından itibaren 10 yıl boyunca veya talebinizi ilettiğiniz takvim yılı boyunca saklanacaktır.
                            <br />
                            Bu Web Sitesi ayrıca çerezler aracılığıyla hakkınızda Kişisel Veri toplayabilir. Web Sitemizi ziyaretiniz sırasında tarama verilerinizi kullanmamızla ilgili her türlü bilgi için Çerez Politikamıza başvurabilirsiniz. Ayrıca, çerez toplama tercihlerinizi istediğiniz zaman Web Sitesindeki Çerez Tercihleri Merkezi aracılığıyla değiştirebilirsiniz.<br />
                            <br />
                        </p>
                        <p>
                            <b>6.	Kişisel Verilerinizi kimlerle paylaşıyoruz?</b><br /><br />
                            Kişisel verileriniz güncel, belirli, açık ve meşru amaçlar için, gerektiğinde ilgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar sınırlı ve ölçülü saklama ilkelerine uygun olarak işlenmektedir.<br /><br />

                            Kişisel verileriniz, Kişisel Verilerin Korunması Kanunu'nun 8. ve 9. maddelerinde öngörülen şartlara uyulması ve gerekli güvenlik önlemlerinin alınması kaydıyla, işbu bildirimde sayılan amaçlarla sınırlı olmak üzere, AXA Partners Grubu ve hatta Axa Grubu bünyesindeki yurtiçi ve yurtdışı merkez ve iştiraklerimize ve ayrıca Türk makamlarına, mevzuatta belirtilen amaçlara tabi faaliyetlerimizi yürütmek üzere işbirliği yaptığımız özel ve/veya kamu kurum ve kuruluşlarına, tedarikçilerimize, denetleyici ve düzenleyici kurumlara, hizmet aldığımız ve/veya verdiğimiz tedarikçi veya yüklenicilere aktarılabilecektir.<br />
                            <br />
                        </p>
                        <p>
                            <b>7.	Kişisel Verilerinizin güvenliğini nasıl sağlıyoruz?</b><br /><br />
                            Hakkınızdaki kişisel bilgileri korumak için tasarlanmış uygun teknik ve organizasyonel önlemler kullanıyoruz. Sağladığımız önlemler, AXA standartlarına uygun olarak, kişisel bilgilerinizin işleme faaliyetinin riskine uygun yeterli bir güvenlik düzeyi sağlamak üzere tasarlanmıştır.
                            <br />
                        </p>
                        <p>
                            <b>8.	Bu işleme bağlamında otomatik bir karar verildi mi?</b><br /><br />
                            Bu Politikada açıklanan işlemler için otomatik karar verme işlemi gerçekleştirilmez.
                            <br />
                        </p>
                        <p>
                            <b>9.	Kişisel Verileriniz nereden geliyor?</b><br /><br />
                            İşlediğimiz Kişisel Veriler, iletişim formu, sigorta şirketleri ve çağrı merkezi tarafından doğrudan sizden toplanır.
                            <br />
                        </p>
                        <p>
                            <b>10.	Bir Denetim Otoritesine nasıl şikayette bulunulur?</b><br /><br />
                            Veri işleme faaliyetlerine ilişkin taleplerin yazılı olarak veya Kişisel Verileri Koruma Kurulunca belirlenecek diğer yöntemlerle veri sorumlusuna iletilmesi zorunludur. Veri sorumlusu, başvuruda yer alan talepleri, talebin niteliğine göre en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandırır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Kurulca belirlenen tarifedeki ücret alınabilir. Veri sorumlusu talebi kabul eder veya gerekçesini açıklayarak reddeder ve durumu ilgili kişiye yazılı olarak veya elektronik ortamda bildirir. Başvuruda yer alan talebin kabul edilmesi halinde veri sorumlusu gereğini yerine getirir. Başvurunun veri sorumlusunun hatasından kaynaklanması hâlinde alınan ücret ilgili kişiye iade edilir.<br /><br />
                            Başvurunun reddedilmesi, verilen cevabın yetersiz bulunması veya süresinde başvuruya cevap verilmemesi hâllerinde; veri sorumlusunun cevabını öğrendiğiniz tarihten itibaren otuz ve her hâlde başvuru tarihinden itibaren altmış gün içinde Kişisel Verileri Koruma Kuruluna şikâyette bulunabilirsiniz.<br /><br />
                            Ayrıntılı bilgi için bkz. https://sikayet.kvkk.gov.tr <br /><br />
                            Yukarıda belirtilen başvuru yolları tüketilmeden Kurul'a şikayette bulunulamaz.<br /><br />
                            Kişilik hakkınızın ihlal edilmesi halinde genel hükümlere göre tazminat hakkınız saklıdır.<br /><br />
                            <br />
                        </p>
                        <p>
                            <b>11.	Mevcut Politikanın Güncellenmesi</b><br /><br />
                            Yasal, teknik veya ticari değişikliklere yanıt olarak bu Politikayı güncelleyebiliriz. Politikamızı güncellediğimizde, yaptığımız değişikliklerin önemine uygun olarak sizi bilgilendirmek için uygun önlemleri alacağız. Geçerli veri koruma yasalarının gerektirmesi halinde, Kişisel Verilerinizin korunması için Politikada yapılacak herhangi bir önemli değişiklik için onayınızı alacağız.<br /><br />
                            Aşağıda görüntülenen "son güncelleme" tarihini kontrol ederek bu Politikanın en son ne zaman güncellendiğini görebilirsiniz.<br /><br />
                            <br />
                        </p>
                        <p>
                            Son güncelleme 12 Temmuz 2024 tarihinde yapılmıştır.
                            <br />
                        </p>
                    </div>
                </div>
            </>
        );
    }
}
