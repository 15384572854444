import "./ServiceDetail.css";
import React, {useEffect, useState} from "react";
import ImageViewer from "_helpers/ImageViewer";
import {useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {ServiceDetailAtom, ServiceFolderListAtom, ServiceStatusInfoAtom} from "../../../state";
import {useServicesActions} from "../../../_actions";
import Util from "../../../util/utilityFunctions/Util";
import DateFormatUtil from "../../../util/utilityFunctions/DateFormatUtil";
import ConstantStatusString from "../../../util/constantStatusString";
import Constants from "../../../util/constantStrings";
import ServiceService from "../../../services/ServiceService";
import ConstantArray from "../../../util/constantArray";
import {GoogleMapTripLayer} from "../../../_helpers/GoogleMapTripLayer";
import {LoadScript} from "@react-google-maps/api";
import ErrorService from "../../../services/ErrorService";
import ProgressBar from "../../../_helpers/ProgressBar";
import warning from "../../../assets/images/warning.png"
import vehicleService from "../../../assets/images/vehicleservice.png"
import towtruck from "../../../assets/images/towtruck.png"


function ServiceDetail() {
  let { id } = useParams();
  const userRole = JSON.parse(localStorage.getItem("user")).role;
  const API_KEY = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
  const [imageTab, setImageTab] = useState(true)
  const [isLoading, setIsLoading] = useState(true);
  const serviceStatusInfoList = useRecoilValue(ServiceStatusInfoAtom);
  const serviceFolderList = useRecoilValue(ServiceFolderListAtom);
  const serviceDetail = useRecoilValue(ServiceDetailAtom);
  const servicesActions = useServicesActions();
  const [towTruckLocationList, setTowTruckLocationList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [towTruckRouteList, setTowTruckRouteList] = useState([]);
  const [showStatusRoute] = useState(false);

  function getTotalTime() {
    if(!Util.checkIsNullOrUndefined(serviceStatusInfoList) && serviceStatusInfoList.length > 0) {
      const statusTakeService = Util.safeArrayElementAccess(serviceStatusInfoList, 0, {});
      const statusArrived = Util.getLastElementOfArray(serviceStatusInfoList, {});
      return parseInt((new Date(statusArrived.recordDate).getTime() - new Date(statusTakeService.recordDate).getTime()) / (1000 * 60))
    } else {
      return 0
    }
  }
  function getTotalDistanceByKm(distance) {
    const km = distance / 1000
    return km.toFixed(1)
  }
  function getStatusById(id) {
    return ConstantStatusString.STATUS_STRING_MAP.get(id);
  }
  function getServiceFolders(statusId){
    const payload = {
      serviceId: id,
      geoStatusId: statusId,
      page: Constants.ALL_DATA_REQUEST_CODE
    }
    servicesActions.getServiceFolderList(payload);
  }
  function partitionRouteList(routeArray) {
    const chunkSize = Constants.ROUTER_LIST_CHUNK_SIZE;
    const groups = routeArray.map((item, index) => {
       return index % chunkSize === Constants.MOD_ZERO ? routeArray.slice(index, index + chunkSize) : null;
    }).filter(routeItem => {
      return routeItem;
    });
    setRouteList(groups);
  }
  function getUniqueLocationRouteList(routeArray){
    let uniqueArray = [...new Map(routeArray.map(routeItem=> [ConstantArray.UNIQUE_ROUTE_ARRAY_KEY.map(keyItem => routeItem.location[keyItem]).join('|'),routeItem])).values()];
    partitionRouteList(uniqueArray);
  }
  function setTowTruckStatusRoute(){
    const statusRouteArray = towTruckLocationList.map((routeItem,index) => ({
      location: {
        lat: Number(routeItem.lat),
        lng: Number(routeItem.lng)
      },
      stopover: false
    }))

    partitionRouteList(statusRouteArray.slice(1));
  }
  function getLocationList() {
    ServiceService.getLocationRouteList({serviceId: id, page: 0}).then(response => {
      const routeArray = response.data.data.locationLogs.map(routeItem => ({
        location: {
          lat: Number(routeItem.latitude),
          lng: Number(routeItem.longitude)
        },
        stopover: false
      }))
      getUniqueLocationRouteList(routeArray)
    }).catch(error => ErrorService.errorConsole(error))
  }
  function getPathArrayOfArray(routeItem){
    return routeItem.path.map(pathItem => (
        [pathItem.longitude, pathItem.latitude]
    ))
  }
  function  getTowTruckRouteDataList() {
    ServiceService.getServiceRoute(id).then(response => {
      const tripLayerRouteArray = response.data.data.map(routeItem => ({
        vendor: routeItem.vendor,
        path: getPathArrayOfArray(routeItem),
        timestamps: routeItem.timestamps
      }))
      tripLayerRouteArray.forEach((item,index) => {
        if(Util.safeArrayCheck(tripLayerRouteArray,index + 1)) {
          item.path.push(tripLayerRouteArray[index + 1].path[0])
          item.timestamps.push(tripLayerRouteArray[index + 1].timestamps[0])
        }
      })
      setTowTruckRouteList(tripLayerRouteArray)
    }).catch(error => ErrorService.errorConsole(error))
  }
  useEffect(()=> {
    if(!Util.checkIsNullOrUndefined(id) && !Util.checkIsNullOrUndefined(id)) {
      servicesActions.getServiceDetailById(id);
      servicesActions.getServiceStatusInfoList(id);
      getServiceFolders(Constants.LOCATION_GEO_STATUS_ID);
    }
    return servicesActions.resetUsers;
  },[])
  useEffect(()=> {
    const locationList = serviceStatusInfoList.map(serviceStatusInfoItem => {
      return {
        lat: Number(serviceStatusInfoItem.latitude),
        lng: Number(serviceStatusInfoItem.longitude),
        statusId: serviceStatusInfoItem.statusId
      }
    });
    setTowTruckLocationList(locationList);
  },[serviceStatusInfoList])
  useEffect(()=>{
    if (showStatusRoute) {
      setTowTruckStatusRoute()
    }else {
      getLocationList();
    }

    getTowTruckRouteDataList()
  },[showStatusRoute])
  return (
    <div>
      <div className="container">
        <div className="row mt-lg-4">
          <div className="col-12">
            <div className="border-white rounded shadow bg-white rounded padding-20">
              <div className="row">
                <p className="col service-detail-text"><strong>Servis Numarası: </strong>{serviceDetail.fdid}</p>
                <p className="col service-detail-text"><strong>Dosya Numarası: </strong>{serviceDetail.fmprotocol }</p>
                <p className="col service-detail-text"><strong>Durum: </strong>{getStatusById(serviceDetail.geoStatusId)}</p>
              </div>
              <div className="row">
                {(serviceStatusInfoList && serviceStatusInfoList.length > 0) && <p className="col service-detail-text"><strong>Toplam Süre(DK): </strong>{getTotalTime(serviceDetail)}</p>}
                {userRole === Constants.SUPERVISOR_ADMIN &&
                    <p className="col service-detail-text"><strong>Toplam Mesafe(KM): </strong>{getTotalDistanceByKm(serviceDetail.totalDistanceMeters)}</p>
                }
              </div>
              <div className="row">
                <p className="col service-detail-text"><strong>Oluşturulma Tarihi: </strong>{DateFormatUtil.getDottedDMYByHourFormatDate(serviceDetail.fddatecreated)}
                </p>
                <p className="col service-detail-text"><strong>Atama Tarihi: </strong>{DateFormatUtil.getDottedDMYByHourFormatDate(serviceDetail.fddatemodified)}
                </p>
              </div>
              <div className="row">
                <p className="col service-detail-text"><strong>Açıklama: </strong>{serviceDetail.fdrnotes}</p>
                <p className="col service-detail-text"><strong>Çekici Plaka: </strong>{serviceDetail.assignCarPlate}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-lg-4">
          <div className="col-12">
            <LoadScript id={"google-map-script"} googleMapsApiKey={API_KEY}>
              <>
                        {(towTruckRouteList && towTruckRouteList.length > 0) &&
                            <GoogleMapTripLayer
                                routeList={routeList}
                                towTruckRouteList={towTruckRouteList}
                                isLoading={isLoading}
                                setIsLoading={(state) => setIsLoading(state)}
                            />
                        }
                      </>
            </LoadScript>
          </div>
        </div>
        <div className="row mt-lg-4">
          <div className="col-12">
            <div className="p-4 service-status-container border-white rounded shadow bg-white rounded" style={{height: '200px', marginBottom: '20px'}}>
            <span><img src={towtruck} /> <span style={{color:'#808080'}}>Hareket Ettim</span></span> 
            <span style={{marginLeft: '40px'}}><img width='22px' height='22px' src={warning} /><span style={{color:'#808080', marginLeft:'8px'}}>Olay Yerine Vardım</span> </span>  
            <span style={{marginLeft: '40px'}}><img width='40px' height='40px' src={vehicleService} /> <span style={{color:'#808080'}}>Servise Vardım</span></span> 
              {(serviceStatusInfoList && serviceStatusInfoList.length > 0) && <ProgressBar serviceStatusInfoList={serviceStatusInfoList}/>}
            </div>
          </div>
        </div>
        <div className="row mt-lg-4">
          <div className="col-12">
            <div className="images-container border-white rounded shadow bg-white rounded">
              <div className="row image-tab">
                <ul className="nav nav-pills nav-fill">
                  <li>
                    <button className={imageTab ? "nav-link active image-tab-link image-tab-text" : "nav-link image-tab-link"}>
                      Olay Yeri Fotoğrafları
                    </button>
                  </li>
                </ul>
              </div>
              <div className="row">
                <ImageViewer serviceFolderList={serviceFolderList}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ServiceDetail };
