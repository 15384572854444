import {atom} from "recoil";

const IncompleteStatusAtom = atom({
  key: "incompleteServices", // unique ID (with respect to other atoms/selectors)
  default: "",
});

const CompleteStatusAtom = atom({
  key: "completeServices", // unique ID (with respect to other atoms/selectors)
  default: "",
});

const AllStatusAtom = atom({
  key: "allServices", // unique ID (with respect to other atoms/selectors)
  default: "",
});

export {IncompleteStatusAtom, CompleteStatusAtom, AllStatusAtom};
