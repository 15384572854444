import {useEffect} from "react";
import Util from "../util/utilityFunctions/Util";
import Constants from "../util/constantStrings";
import {GoogleMapsOverlay} from "@deck.gl/google-maps";
import {TripsLayer} from "deck.gl";
import ConstantStatusIcon from "../util/constantStatusIcon";

const GoogleMapTripLayer = ({routeList, towTruckRouteList, isLoading, setIsLoading}) => {
    const LOOP_LENGTH = Number.MAX_VALUE;
    const VENDOR_COLORS = [
        [255, 123, 78],
        [12, 65, 58],
        [255, 0, 0],
        [0, 0, 255],
    ];

    function getMovedStatusObject() {
        const statusMovedPath = Util.safeArrayElementAccess(towTruckRouteList, 0, {});
        const statusMovedCoordinate = Util.safeArrayElementAccess(statusMovedPath?.path, 0, []);
        const statusMovedCoordinateObject = statusMovedCoordinate.length > 0 ? {
            lat: statusMovedCoordinate[1],
            lng: statusMovedCoordinate[0],
            statusId: 2
        } : {statusId: 0};
        return statusMovedCoordinateObject;
    }

    function getArrivedStatusObject() {
        const statusArrivedPath = Util.safeArrayElementAccess(towTruckRouteList, 0, {});
        const statusArrivedCoordinate = Util.getLastElementOfArray(statusArrivedPath?.path, []);
        const statusArrivedCoordinateObject = statusArrivedCoordinate.length > 0 ? {
            lat: statusArrivedCoordinate[1],
            lng: statusArrivedCoordinate[0],
            statusId: 3
        } : {statusId: 0};
        return statusArrivedCoordinateObject;
    }

    function getFinishStatusObject() {
        const statusFinishPath = Util.safeArrayElementAccess(towTruckRouteList, 1, {});
        const statusFinishCoordinate = Util.getLastElementOfArray(statusFinishPath?.path, []);
        const statusFinishCoordinateObject = statusFinishCoordinate.length > 0 ? {
            lat: statusFinishCoordinate[1],
            lng: statusFinishCoordinate[0],
            statusId: 4
        } : {statusId: 0};
        return statusFinishCoordinateObject;
    }

    function getStatusChangeCoordinates() {
        const statusMovedCoordinateObject = getMovedStatusObject();
        const statusArrivedCoordinateObject = getArrivedStatusObject();
        const statusFinishCoordinateObject = getFinishStatusObject();
        return [statusMovedCoordinateObject,statusArrivedCoordinateObject,statusFinishCoordinateObject];
    }
    function getIcon(locationItem) {
        return ConstantStatusIcon.STATUS_ICON_MAP.get(locationItem.statusId);

    }
    function getMaxValueTimeStamp() {
        let max;
        let timeStampArray= [];
        towTruckRouteList.forEach(item => item.timestamps.forEach(item2 => timeStampArray.push(item2)))
        max = Math.max(
            ...timeStampArray.map(item => {
                return item;
            })
        )
        return max
    }
    function getMinValueTimeStamp() {
        let min;
        let timeStampArray= [];
        towTruckRouteList.forEach(item => item.timestamps.forEach(item2 => timeStampArray.push(item2)))
        min = Math.min(
            ...timeStampArray.map(item => {
                return item;
            })
        )
        return min
    }
    function drawMap(google) {
        const map = new google.maps.Map(document.getElementById("map"),
            {
                center: { lat: towTruckRouteList[0]?.path[0][1], lng: towTruckRouteList[0]?.path[0][0]},
                zoom: Constants.GOOGLE_MAP_ZOOM,
                tilt: 45,
            }
        );
        const maxValue = getMaxValueTimeStamp()
        const minValue = getMinValueTimeStamp()
        let currentTime = 0;
        const props = {
            id: "trips",
            data: towTruckRouteList.map(item1 => ({
                ...item1,
                timestamps: item1.timestamps.map(item2 => (item2-minValue)*1000/(maxValue-minValue)),
                timestamps2: item1.timestamps.map(item2 => item2),
            })),
            getPath: (d) => d.path,
            getTimestamps: (d) => d.timestamps.map(time => time),
            getColor: (d) => VENDOR_COLORS[d.vendor],
            opacity: 1,
            widthMinPixels: 3,
            trailLength: 180,
            fadeTrail: false,
            currentTime,
            shadowEnabled: false,
        };
        const overlay = new GoogleMapsOverlay({});
        const animate = () => {
            currentTime = (currentTime + 1) % LOOP_LENGTH;
            const tripsLayer = new TripsLayer({
                ...props,
                currentTime,
            });
            overlay.setProps({
                layers: [tripsLayer],
            });
            window.requestAnimationFrame(animate);
        };
        window.requestAnimationFrame(animate);
        const statusRouteList = getStatusChangeCoordinates();
        statusRouteList.forEach((locationItem) => {
            if(locationItem.statusId > 1) {
                new google.maps.Marker({
                    position: locationItem,
                    map,
                    icon: getIcon(locationItem)
                })
            }
        })
        overlay.setMap(map);
    }
    useEffect(()=> {
        setTimeout(()=> {
            if(!Util.checkIsNullOrUndefined(routeList)) {
                drawMap(window.google);
            } else {
                setIsLoading(false);
            }
        },3000);
    }, [routeList,window])
    return (
        <div>
            <div id="map" style={{width: "100wh", height: "350px"}} hidden={!isLoading}/>
        </div>
    )
}
export {GoogleMapTripLayer}