class Constants{
    static APP_ID = "980560";
    static ADMIN = "Admin";
    static PROVIDER = "Provider";
    static SUPERVISOR_ADMIN = "SupervisorAdmin";
    static USER = "user";
    static HOME_PAGE_DATE_FILTER_DAY = "day";
    static HOME_PAGE_DATE_FILTER_WEEK = "week";
    static HOME_PAGE_DATE_FILTER_MONTH = "month";
    static DATE_FORMAT_WITH_HOUR_HM = "DD.MM.yyyy HH:mm";
    static GOOGLE_MAP_ZOOM = 12;
    static DEFAULT_TOW_TRUCK_LOCATION_STATUS_ID = 1;
    static ALL_DATA_REQUEST_CODE = 1;
    static INCOMPLETED_SERVICE_CODE = 0;
    static COMPLETED_SERVICE_CODE = 1;
    static LOCATION_GEO_STATUS_ID = 3;
    static SERVICE_GEO_STATUS_ID = 4;
    static IN_COMPLETED_SERVICE = true;
    static COMPLETED_SERVICE = false;
    static ROUTER_LIST_CHUNK_SIZE = 20;
    static MOD_ZERO = 0;
    static ARRAY_ZERO_INDEX = 0;
    static GOOGLE_MAP_API_OVER_QUERY_LIMIT = "OVER_QUERY_LIMIT";
    static EXPONENTIAL_LIMIT = 15; // 2^20 ms = 17.5 min
    static REJECT_REASON_CODE = 4;
    static RESPONSE_STATUS_SUCCESS_STRING = "Success";
    static RESPONSE_STATUS_ERROR_STRING = "Error";
    static PROGRESS_HUNDRED_PERCENT = 100;
    static PROGRESS_ZERO_PERCENT = 0;
    static PROGRESS_STEP_PERCENT = 33;
    static PLATE_ASSIGNMENT_OR_CHANGE_SUCCESS_MESSAGE = "Plaka atama/değiştirme başarılı";
    static PLATE_ASSIGNMENT_OR_CHANGE_ERROR_MESSAGE = "Plaka atama/değiştirme yapılırken bir sorun oluştu";
    static PLATE_IDENTIFY_SUCCESS_MESSAGE = "Yeni plaka tanımlama başarılı";
    static PLATE_IDENTIFY_ERROR_MESSAGE = "Yeni plaka tanımlanırken bir sorun oluştu";
    static CUSTOMER_LOCATION_PERMISSION_MESSAGE = "Çekicimizin konumunuzu görebilmesi için, konum ayarlarınızın açık olması ve konum isteğine izin vermeniz gerekmektedir.";
    static MATRIX_DISTANCE_COEFFICIENT = 1.20;
    static MATRIX_DURATION_COEFFICIENT = 1.10;
    static MATRIX_DURATION_TOLERANCE_SECOND_TIME = 900;
    static MILLISECOND_TIME_CONVERT_COEFFICIENT = 3600;
    static MINUTE_TIME_CONVERT_COEFFICIENT = 60;
    static REFRESH_TIME_GET_TOW_TRUCK_LOCATION = 5000;
    static REFRESH_TIME_SERVICE_TABLES = 60000;
    static DISPLAY_PLATE_SUBSTRING_COUNT = 2;
}
export default Constants;