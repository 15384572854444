import AxiosService from "./AxiosService";
import UserService from "./UserService";
import Constants from "../util/constantStrings";
const BASE_URL = `${process.env.REACT_APP_API_URL}/api/TowTruck`;
const TowTruckService = {
    getCarPlateByImei(imei) {
        const config = {
            url: `${BASE_URL}/CarPlate?appId=${Constants.APP_ID}&imei=${imei}`,
        }
        return AxiosService.get(config.url,{})
    },
    getProviderCarPlateList(param){
        const config = {
            url: `${BASE_URL}/TowTruckAgents?appId=${Constants.APP_ID}&providerId=${UserService.getUserProviderId()}`,
            params: param
        }
        return AxiosService.get(config.url,{},config.params)
    },
    getAllCarPlateList(param){
        const config = {
            url: `${BASE_URL}/TowTruckAgents?appId=${Constants.APP_ID}`,
            params: param
        }
        return AxiosService.get(config.url,{},config.params)
    },
    getTowTruckLocationInfoByServiceId(serviceId){
        const config = {
            url: `${BASE_URL}/TowTruckLastLocationByServiceId?appId=${Constants.APP_ID}&serviceId=${serviceId}`,
        }
        return AxiosService.get(config.url,{})
    },
    getTowTruckInfoByServiceId(param){
        const config = {
            url: `${BASE_URL}/TowTruckLastLocationAndStatus?appId=${Constants.APP_ID}&serviceId=${param.serviceId}&page=${param.page}`,
        }
        return AxiosService.get(config.url,{})
    },
    getTowTruckLocationInfoByProviderId(param){
        const config = {
            url: `${BASE_URL}/TowTruckLastLocationAndStatus?appId=${Constants.APP_ID}&providerId=${UserService.getUserProviderId()}&page=${param.page}`,
        }
        return AxiosService.get(config.url,{})
    },
    getTowTruckLocationInfoByGuid(guid){
        const config = {
            url: `${BASE_URL}/TowTruckLastLocation?appId=${Constants.APP_ID}&serviceGuid=${guid}`,
        }
        return AxiosService.get(config.url,{})
    },
    postTowTruckAgents(params) {
        const config = {
            url: `${BASE_URL}/TowTruckAgents?appId=${Constants.APP_ID}`,
            data: params
        }
        return AxiosService.post(config.url,config.data)
    },
    getAllTowTruckLocation(param){
        const config = {
            url: `${BASE_URL}/AllTowTruckLocation?appId=${Constants.APP_ID}&page=${param.page}`,
        }
        return AxiosService.get(config.url,{})
    }
}
export default TowTruckService;