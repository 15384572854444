import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {useRecoilValue} from "recoil";
import {AllStatusAtom, CurrentServicesAtom} from "state";
import {useStatusActions} from "_actions";

export default forwardRef((props, ref) => {
  const [status, setYear] = useState("All");
  const allStatus = useRecoilValue(AllStatusAtom);
  const services = useRecoilValue(CurrentServicesAtom);
  const statusActions = useStatusActions();
  const uniqueStatus = [];

  services.map((service) => {
    if (!uniqueStatus.some((v) => v == service.Durum)) {
      uniqueStatus.push(service.Durum);
    }
  });

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        if (status == "All") {
          return params;
        } else {
          return params.data.Durum == status;
        }
      },

      isFilterActive() {
        return status === status;
      },

      // this example isn't using getModel() and setModel(),
      // so safe to just leave these empty. don't do this in your code!!!
      getModel() {},

      setModel() {},
    };
  });

  const onYearChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    statusActions.getAllStatus();

    props.filterChangedCallback();
  }, [status]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: 5,
      }}
      onChange={onYearChange}
    >
      <label>
        <input
          type="radio"
          name="status"
          value="All"
          checked={status === "All"}
        />{" "}
        Hepsi
      </label>
      {uniqueStatus.map((service) => (
        <label>
          <input type="radio" name="status" value={service} /> {service}
        </label>
      ))}
    </div>
  );
});
