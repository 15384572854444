class ConstantArray {
    static STATUS_WAITING = 0;
    static STATUS_GET_SERVICE_STRING = 1;
    static STATUS_ON_WAY_STRING = 2;
    static STATUS_ARRIVED_LOCATION = 3;
    static KEY_LATITUDE = "lat"
    static KEY_LONGITUDE = "lng"
    static PLATE_CHANGEABLE_STATUS_ID_LIST = [this.STATUS_WAITING ,this.STATUS_GET_SERVICE_STRING,this.STATUS_ON_WAY_STRING,this.STATUS_ARRIVED_LOCATION]
    static UNIQUE_ROUTE_ARRAY_KEY = [this.KEY_LATITUDE,this.KEY_LONGITUDE]
}
export default ConstantArray;