import serviceLocationIcon from "../assets/images/vehicleservice.png";
import towTruckLocationIcon from "../assets/images/towtruck.png";
import customerLocationIcon from "../assets/images/warning.png";
class ConstantStatusIcon {
    static STATUS_ICON_MAP = ConstantStatusIcon.buildStatusIconMap();

    static buildStatusIconMap(){
        const statusIconMap = new Map();
        statusIconMap.set( 0, towTruckLocationIcon);
        statusIconMap.set( 1, towTruckLocationIcon);
        statusIconMap.set( 2, towTruckLocationIcon);
        statusIconMap.set( 3, customerLocationIcon);
        statusIconMap.set( 4, serviceLocationIcon);
        return statusIconMap;
    }
}
export default ConstantStatusIcon;