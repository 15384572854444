import "../incomplete-services/IncompleteServicesComponent.css";
import React from "react";
import Util from "../../../util/utilityFunctions/Util"
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import RoleUtil from "../../../util/utilityFunctions/RoleUtil";
import ConstantArray from "../../../util/constantArray";


BtnCellRenderer.propTypes = {
    setIsOpenAssignPlateModal: PropTypes.func,
    setIsOpenRejectServiceModal: PropTypes.func,
    gridRowData: PropTypes.object,
    isShowOnlyServiceDetail: PropTypes.bool
}
BtnCellRenderer.defaultProps  = {
    setIsOpenAssignPlateModal: () => {},
    setIsOpenRejectServiceModal: () => {},
    gridRowData: {},
    isShowOnlyServiceDetail: false
}

function BtnCellRenderer({setIsOpenAssignPlateModal,gridRowData,setIsOpenRejectServiceModal,isShowOnlyServiceDetail}) {
  function isShowPlateAssignPlateButtonByStatusId(statusId){
      return  ConstantArray.PLATE_CHANGEABLE_STATUS_ID_LIST.includes(statusId)

  }
  function hasAssignPlate(){
    return (Util.checkIsNullOrUndefined(gridRowData.assignCarPlate) && !RoleUtil.isAdmin() && !RoleUtil.isSupervisorAdmin())
  }
  function hasArrived(){
      return (!Util.checkIsNullOrUndefined(gridRowData.assignCarPlate) && !Util.checkIsEmptyString(gridRowData.assignCarPlate)) &&isShowPlateAssignPlateButtonByStatusId(gridRowData.geoStatusId)
          &&
          !RoleUtil.isAdmin() && !RoleUtil.isSupervisorAdmin();
  }
  return (
    <>
      <div className="flex-shrink-0 action_button_container">
          {!isShowOnlyServiceDetail && (<>
              {hasAssignPlate() && (<button
                  className="btn table-buttons"
                  style={{backgroundColor: "#138636", color: "white"}}
                  onClick={() => setIsOpenAssignPlateModal(true)}
              >
                  <i className="ri-add-line align-bottom"/>
                  Plaka Ata
              </button>)}
              {hasArrived() && (
                  <>
                  <button
                  className="btn table-buttons"
                  style={{backgroundColor: "#138636", color: "white"}}
                  onClick={() => setIsOpenAssignPlateModal(true)}
              >
                  <i className="ri-add-line align-bottom"/>
                  Plaka
              </button>
              <button
                  className="btn table-buttons"
                  style={{backgroundColor: "#c91432", color: "white"}}
                  onClick={() => setIsOpenRejectServiceModal(true)}
              >
                  <i className=" ri-close-line align-bottom"/> Reddet
              </button>
                  </>)}
          </>)}
          <Link to={`/service-detail/${gridRowData.fdid}`} target="_blank">
              <button
                  className="btn table-buttons"
                  style={{ backgroundColor: "#343C3D", color: "white" }}
              >
                  <i className="ri-search-line align-bottom"/>
              </button>
          </Link>
      </div>


    </>
  );
}

export { BtnCellRenderer };
