import React, {useEffect, useState} from "react";
import Progress from "@delowar/react-circle-progressbar";
import "./HomeComponent.css";
import GraphService from "services/GraphService";
import RoleUtil from "util/utilityFunctions/RoleUtil";
import UserService from "../../../services/UserService";
import ErrorService from "../../../services/ErrorService";
import Constants from "../../../util/constantStrings";
function HomeComponent() {
  const [serviceCounts, setServiceCounts] = useState();
  const [percentangeServiceInfo, setPercentageServiceInfo] = useState({completedService: 0, inProgress: 0});
  const [plateCounts, setPlateCounts] = useState(0)
  const [user, setUser] = useState()

  function calculatePercentageServiceStatus(serviceCountInfo){
    const completed = ((serviceCountInfo.countClosedServices / serviceCountInfo.countServices) * 100).toFixed(2);
    const continuing =((serviceCountInfo.countContinuingServices / serviceCountInfo.countServices) * 100).toFixed(2);
    setPercentageServiceInfo({completedService: completed, inProgress: continuing})
  }

  function getServiceCountInfos(){

    if (RoleUtil.isAdmin() || RoleUtil.isSupervisorAdmin()) {
      const payload = {
        date: Constants.HOME_PAGE_DATE_FILTER_DAY
      }
      GraphService.getServiceData(payload).then(response => {
            setServiceCounts({...response?.data.data})
            calculatePercentageServiceStatus(response.data.data)
        }).catch(error => ErrorService.errorConsole(error))
    } else {
      const payload = {
        date: Constants.HOME_PAGE_DATE_FILTER_DAY,
        id: user.providerId
      }
      GraphService.getServiceDataById(payload).then(response => {
          setServiceCounts({...response?.data.data})
          calculatePercentageServiceStatus(response.data.data)
      }).catch(error => ErrorService.errorConsole(error))
    }
    
  }

  function getPlateCountInfo(){
    if (RoleUtil.isAdmin() || RoleUtil.isSupervisorAdmin()) {
      GraphService.getTowTruckAgentData().then(response => {
            setPlateCounts(response.data.data.plateCount)
        }).catch(error => ErrorService.errorConsole(error))
    } else {
      GraphService.getTowTruckAgentDataById(user.providerId).then(response => {
            setPlateCounts(response.data.data.plateCount)
        }).catch(error => ErrorService.errorConsole(error))
    }
  }

  function getUserRole(){
    const user = UserService.getUser()
    setUser(user)
  }

  useEffect(()=>{
    if(RoleUtil.checkHasAnyRole(user?.role)) {
      getServiceCountInfos()
      getPlateCountInfo()
    } else {
      getUserRole()
    }
  },[user])
  return (
      <div className="p-4">
        {serviceCounts &&<div className="container">
        <div className="row justify-content-around border-white rounded shadow bg-white rounded card-animate">
          <div className="col-6 login-page-cards">
            <div className="row justify-content-start login-page-row">
              <div className="col-5 login-page-card">
                <div className="col box">
                  <div className="counting-box total-service-box">
                    <p className="fs-5 text-font">
                      <b>{serviceCounts?.countServices}</b>
                      Servis Sayısı
                    </p>
                    <i className="icon-total-service"/>
                  </div>
                </div>
              </div>
              <div className="col-5 login-page-card login-page-card-waiting">
                <div className="col box">
                  <div className="counting-box waiting-service-box">
                    <p className="fs-5 text-font">
                      <b>{serviceCounts?.countWaitingServices}</b>
                      Bekleyen Servis
                    </p>
                    <i className="icon-waiting-service"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-start login-page-row">
              <div className="col-5 login-page-card">
                <div className="col box">
                  <div className="counting-box completed-service-box">
                    <p className="fs-5 text-font">
                      <b>{serviceCounts?.countClosedServices}</b>
                      Tamamlanan Servis
                    </p>
                    <i className="icon-completed-service"/>
                  </div>
                </div>
              </div>
              <div className="col-6 login-page-card login-page-card-defined-plate">
                <div className="col box">
                  <div className="counting-box defined-plate-box">
                    <p className="fs-5 text-font">
                      <b>{plateCounts}</b>
                      Tanımlı Plaka
                    </p>
                    <i className="icon-defined-plate"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col home-component-right-side">
            <div className="row justify-content-center progress-row">
              <div className="col progress-container">
                <div className="progress-bar-title fs-5 text-font">Tamamlanmış Servis</div>
                <div className="progress-div">
                  {percentangeServiceInfo.completedService && <Progress
                    isGradient
                    percent={percentangeServiceInfo.completedService}
                    gradient={{
                      angle: 0,
                      startColor: "#138636",
                      stopColor: "#138636",
                      backgroundColor: "#3C5971",
                    }}
                    emptyColor="#3C5971 "
                    borderWidth="12"
                    borderBgWidth="12"
                  >
                    <div className="progress-bar-number">%{percentangeServiceInfo.completedService}</div>
                  </Progress>}
                </div>
              </div>

              <div className="col progress-container align-self-center">
                <p className="progress-bar-title fs-5 text-font">Tamamlanmamış Servis</p>
                <div className="progress-div">
                  {percentangeServiceInfo.inProgress && <Progress
                    isGradient
                    percent={percentangeServiceInfo.inProgress}
                    gradient={{
                      angle: 0,
                      startColor: "#c91432",
                      stopColor: "#c91432",
                      backgroundColor: "#3C5971",
                    }}
                    emptyColor="#3C5971 "
                    borderWidth="12"
                    borderBgWidth="12"
                  >
                    <div className="progress-bar-number">%{percentangeServiceInfo.inProgress}</div>
                  </Progress>}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>}
      </div>
  );
}

export { HomeComponent };
