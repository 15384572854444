class ConstantStatusString {
    static STATUS_GET_SERVICE_ID = 1;
    static STATUS_ON_WAY_ID = 2;
    static STATUS_ARRIVED_LOCATION_ID = 3;
    static STATUS_ARRIVED_SERVICE_FINISH_JOB_ID = 4;
    static STATUS_WAITING = "Bekliyor";
    static STATUS_GET_SERVICE_STRING = "Servisi Aldım";
    static STATUS_ON_WAY_STRING = "Hareket Ettim";
    static STATUS_ARRIVED_LOCATION = "Olay Yerine Vardım";
    static STATUS_ARRIVED_SERVICE_FINISH_JOB = "Servise Vardım";
    static STATUS_STRING_MAP = ConstantStatusString.buildStatusMap();
    static STATUS_ICON_STRING_MAP = ConstantStatusString.buildStatusIconMap();
    static STATUS_PROGRESS_STRING_MAP = ConstantStatusString.buildStatusProgressMap();
    static buildStatusMap(){
        const statusMap = new Map();
        statusMap.set( 0, this.STATUS_WAITING);
        statusMap.set( 1, this.STATUS_GET_SERVICE_STRING);
        statusMap.set( 2, this.STATUS_ON_WAY_STRING);
        statusMap.set( 3, this.STATUS_ARRIVED_LOCATION);
        statusMap.set( 4, this.STATUS_ARRIVED_SERVICE_FINISH_JOB);
        return statusMap;
    }
    static buildStatusIconMap(){
        const statusIconMap = new Map();
        statusIconMap.set( 0, "ri-loader-line");
        statusIconMap.set( 1, "ri-road-map-line");
        statusIconMap.set( 2, "ri-map-pin-line");
        statusIconMap.set( 3, "location-check");
        statusIconMap.set( 4, "ri-check-line");
        return statusIconMap;
    }
    static buildStatusProgressMap(){
        const statusProgressMap = new Map();
        statusProgressMap.set( 0, 1);
        statusProgressMap.set( 1, 2);
        statusProgressMap.set( 2, 3);
        statusProgressMap.set( 3, 4);
        return statusProgressMap;
    }
}
export default ConstantStatusString;