import AxiosService from "./AxiosService";
import Constants from "../util/constantStrings";
const BASE_URL = `${process.env.REACT_APP_API_URL}/api/Provider`;

const ProviderService = {
    getProviderList(paging){
        const config = {
            url: `${BASE_URL}/Get?appId=${Constants.APP_ID}&page=${paging}`,
        }
        return AxiosService.get(config.url,{})
    },
    getProviderById(id){
        const config = {
            url: `${BASE_URL}/GetbyId?appId=${Constants.APP_ID}&providerId=${id}`,
        }
        return AxiosService.get(config.url,{})
    }
}
export default ProviderService;