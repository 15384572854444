import React, {
  useRef,
  useMemo, useState, useEffect
} from "react";
import { BtnCellRenderer } from "../services/BtnCellRenderer";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./IncompleteServicesComponent.css";
import ServicesAdvanceSearch from "../services/ServicesAdvanceSearch.jsx";
import DateFormatUtil from "../../../util/utilityFunctions/DateFormatUtil";
import Util from "../../../util/utilityFunctions/Util";
import Constants from "../../../util/constantStrings";
import {useRecoilValue} from "recoil";
import {PlateAtom} from "../../../state";
import {useServicesActions} from "../../../_actions";
import Modal from "../../../_helpers/modal/Modal";
import ServiceService from "../../../services/ServiceService";
import ConstantStatusString from "../../../util/constantStatusString";
import ConstantDataGridString from "../../../util/constantDataGridString";
import ConstantColorString from "../../../util/constantColorString";
import PagingUtil from "../../../util/utilityFunctions/PagingUtil"
import RoleUtil from "../../../util/utilityFunctions/RoleUtil";
import ErrorService from "../../../services/ErrorService";
import {errorToast, successToast} from "../../../_helpers/ToastMessages";
import DataGridTooltip from "../services/DataGridTooltip";
export { IncompleteServicesComponent };

function IncompleteServicesComponent() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isOpenAssignPlateModal, setIsOpenAssignPlateModal] = useState(false);
  const [isOpenRejectServiceModal, setIsOpenRejectServiceModal] = useState(false);
  const [selectedPlate, setSelectedPlate] = useState();
  const [selectedService, setSelectedService] = useState();
  const [searchParams, setSearchParams] = useState();
  const [toastMessage, setToastMessage] = useState("");
  const [showSuccessToastMessage, setSuccessShowToastMessage] = useState(false);
  const [showErrorToastMessage, setErrorShowToastMessage] = useState(false);
  const userRole = JSON.parse(localStorage.getItem("user")).role;
  const plateList = useRecoilValue(PlateAtom);
  const servicesActions = useServicesActions();
  const gridRef = useRef();
  const dataSource = {
    rowCount: undefined,
    getRows: (params) => {
      const payload = {
        page:PagingUtil.computedPageFromParams(params),
        isCompleted: Constants.INCOMPLETED_SERVICE_CODE,
        ...searchParams
      }
      if(RoleUtil.isAdmin() || RoleUtil.isSupervisorAdmin()) {
        ServiceService.getServiceList(payload).then(response => {
          params.successCallback(response.data.data.services,response.data.data.pages * ConstantDataGridString.DATA_GRID_PAGE_SIZE)
        }).catch(error => {
          ErrorService.errorConsole(error);
          params.failCallback();
        })
      }else {
        ServiceService.getServiceListByProviderId(payload).then(response => {
          params.successCallback(response.data.data.services, response.data.data.pages * ConstantDataGridString.DATA_GRID_PAGE_SIZE)
        }).catch(error => {
          ErrorService.errorConsole(error);
          params.failCallback();
        })
      }
    }
  };
  const defaultColDef = useMemo(() => ({
    flex: 1,
    resizable: true,
    filterParams: {
      filterOptions: null,
    },
    tooltipComponent: DataGridTooltip,
  }));
  const defaultPlateGridColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
    minWidth: 120,
    resizable: true,
    floatingFilter: true,
  }));
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  function getPlateList() {
    servicesActions.getPlates(ConstantDataGridString.START_PAGE_NUMBER);
    return servicesActions.resetUsers;
  }
  function getAssignPlateModalBody(){
    return (
        <Modal open={isOpenAssignPlateModal} onClose={() => setIsOpenAssignPlateModal(prevState => !prevState)}>
          <div className="ag-theme-alpine ag-grid-modal ">
            <AgGridReact
                rowData={plateList}
                columnDefs={[
                  {
                    headerName: "Plaka",
                    field: "plateNumber",
                    cellRenderer: function (params) {
                      return (
                          <div className="platesDiv">
                            <p className="plate">
                              <span>TR</span>
                              {params?.value?.toUpperCase()}
                            </p>
                          </div>
                      );
                    },
                  },
                ]}
                defaultColDef={defaultPlateGridColDef}
                animateRows={true}
                rowSelection="multiple"
                rowHeight={ConstantDataGridString.PLATE_DATA_GRID_ROW_HEIGHT}
                onCellClicked={(event) => setSelectedPlate(event.data)}
            />
          </div>

          <button type="submit" className="btn btn-primary right" onClick={()=> assignNewPlate()}>
            Kaydet
          </button>
        </Modal>
    )
  }
  function assignNewPlate(){
    const payload = {
      serviceId: selectedService.fdid,
      towTruckAgentId: selectedPlate.id
    }
    ServiceService.postAssignTowTruckToService(payload).then(() => {
      setToastMessage(Constants.PLATE_ASSIGNMENT_OR_CHANGE_SUCCESS_MESSAGE);
      setSuccessShowToastMessage(true);
      setIsOpenAssignPlateModal(false);
      setInterval(() => {
        setSuccessShowToastMessage(false);
      }, 4000)
      onGridReady(gridRef.current)
    }).catch(error => {
      setToastMessage(Constants.PLATE_ASSIGNMENT_OR_CHANGE_ERROR_MESSAGE);
      setErrorShowToastMessage(true);
      setInterval(() => {
        setErrorShowToastMessage(false);
      }, 4000)
      ErrorService.errorConsole(error);
    })
  }
  function getRejectServiceModalBody(){
    return (
        <Modal
            open={isOpenRejectServiceModal}
            onClose={() => setIsOpenRejectServiceModal(prevState => !prevState)}>
          <p className={"reject_modal_info_text"}>
            Size atanmış işi reddetmek istediğinize emin misiniz?
          </p>
          <div className="mt-5 text-center">
                <button
                    className="btn table-buttons reject_modal_reject_button"
                    onClick={()=> onRejectService()}
                >
                 Reddet
                </button>
                <button
                    className="btn table-buttons reject_modal_close_button"
                    onClick={()=> setIsOpenRejectServiceModal(false)}
                >
                  Vazgeç
                </button>
            </div>
        </Modal>
    )
  }
  function onRejectService(){
    const payload = {
      serviceId:selectedService.fdid,
      reasonId: Constants.REJECT_REASON_CODE
    }
    ServiceService.postRejectService(payload).then(()=> {
      setIsOpenRejectServiceModal(prevState => !prevState)
      onGridReady(gridRef.current)
    }).catch(()=> setIsOpenRejectServiceModal(prevState => !prevState))
  }
  function cellStyle(params) {
    return {color: ConstantColorString.GRID_COLOR_MAP.get(params.value)}
  }
  function onGridReady(params) {
    params.api.setDatasource(dataSource)
  }
  function getRowStyle(params) {
    if (params.node.rowIndex % 2 === 0) {
      return { background: ConstantColorString.DATA_GRID_ROW_EVEN_ROW };
    }
  }
  function getDataGridColumInfo(){
    return [
      {
        headerName: "Dosya No",
        field: "fmprotocol",
        resizable: true,
        suppressSizeToFit: false,
        minWidth: 70,
        maxWidth: 140,
        hide: windowSize.innerWidth <= 530
      },
      {
        headerName: "Atanan Plaka",
        field: "assignCarPlate",
        minWidth: 125,
        maxWidth: 150,
        hide: userRole === Constants.SUPERVISOR_ADMIN,
        cellRenderer: function (params) {
          return (
              <>
                {params?.value && (
                    <div className="plateDiv">
                  <p className="plate">
                    <span>TR</span>
                    {Util.nullSafe(params?.value.toUpperCase())}
                  </p>
                </div>)
        }
              </>
          );
        },
      },
      {
        headerName: "Tedarikçi",
        field: "partner",
        minWidth: 80,
        maxWidth: 150,
        hide: userRole !== Constants.SUPERVISOR_ADMIN,
      },
      {
        headerName: "Sigortalı Plaka",
        field: "customerCarPlate",
        minWidth: 80,
        maxWidth: 150,
        cellRenderer: function (params) {

          return (
              <>
                {params?.value && <div className="plateDiv">
                  <p className="plate">
                    <span>TR</span>
                    {Util.nullSafe(params?.value.toUpperCase())}
                  </p>
                </div>}
              </>
          );
        },
        hide: windowSize.innerWidth <= 530
      },
      {
        headerName: "Açıklama",
        field: "fdrnotes",
        minWidth: 75,
        maxWidth: 200,
        hide: windowSize.innerWidth <= 530,
        tooltipField: 'fdrnotes'
      },
      {
        headerName: "Durum",
        field: "geoStatusId",
        minWidth: 125,
        maxWidth: 250,
        cellStyle: cellStyle,
        cellRenderer: function (params) {
          return <span><i className={`${ConstantStatusString.STATUS_ICON_STRING_MAP.get(params.value)} align-bottom me-1`}/>{ConstantStatusString.STATUS_STRING_MAP.get(params.value)}</span>
        },
      },
      {
        headerName: "Tarih",
        field: "fddatecreated",
        minWidth: 80,
        maxWidth: 175,
        valueFormatter:(params)=>  DateFormatUtil.getDottedDMYByHourFormatDate(params.value),
        hide: windowSize.innerWidth <= 530
      },
      {
        headerName: "Son Statü Tarihi",
        field: "lastStatuDate",
        minWidth: 80,
        maxWidth: 175,
        valueFormatter:(params)=>  DateFormatUtil.getDottedDMYByHourFormatDate(params.value),
        hide: windowSize.innerWidth <= 530
      },
      {
        headerName: "İşlemler",
        filter: false,
        field: "",
        maxWidth: 800,
        minWidth: 350,
        cellRenderer: function(params) {
          return (
              <>
                {params.node.data &&  <BtnCellRenderer
                    setIsOpenRejectServiceModal={(value) => setIsOpenRejectServiceModal(value)}
                    setIsOpenAssignPlateModal={(value) => setIsOpenAssignPlateModal(value)}
                    gridRowData={params.node.data}/>
                }</>
          )
        },
        cellRendererParams: {
          clicked: function (field) {
            alert(`${field} was clicked`);
          },
        },
      },
    ]
  }
  function getDataGridOptions(){
    return{
      suppressRowHoverHighlight: true,
      columnHoverHighlight: false,
    }
  }
  function onSearch(){
    onGridReady(gridRef.current)
  }
  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }
  useEffect(()=>{
    if(!RoleUtil.isAdmin() && !RoleUtil.isSupervisorAdmin()) {
      getPlateList()
    }
  },[])
  useEffect(()=> {
    setInterval(() => {
      onGridReady(gridRef.current)
    }, Constants.REFRESH_TIME_SERVICE_TABLES)
  },[])
  useEffect(()=>{
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[])
  return (
      <div className="flex-container table-container">
        {showSuccessToastMessage && successToast(toastMessage)}
        {showErrorToastMessage && errorToast(toastMessage)}
        <div className="ag-theme-alpine react" style={{ height: 465 }}>
          <ServicesAdvanceSearch searchParams={searchParams} setSearchParams={(params)=> setSearchParams(params)} onSearch={(searchParams)=> onSearch(searchParams)} isCompleted={false}/>
          <AgGridReact
            {...getDataGridOptions()}
            rowClass={"grid-row"}
            getRowStyle={getRowStyle}
            ref={gridRef}
            rowModelType="infinite"
            columnDefs={getDataGridColumInfo()}
            cacheBlockSize={ConstantDataGridString.DATA_GRID_PAGE_SIZE}
            cacheOverflowSize={0}
            defaultColDef={defaultColDef}
            rowHeight={ConstantDataGridString.DATA_GRID_ROW_HEIGHT}
            onGridReady={onGridReady}
            onCellClicked={(event) => setSelectedService(event.data)}
            tooltipShowDelay={0}
            tooltipHideDelay={5000}
          />
          {isOpenAssignPlateModal && getAssignPlateModalBody()}
          {isOpenRejectServiceModal && getRejectServiceModalBody()}

        </div>
      </div>
  );
}
