import AxiosService from "./AxiosService";
import UserService from "./UserService";
import Constants from "../util/constantStrings";
const BASE_URL = `${process.env.REACT_APP_API_URL}/api/Service`;
const ServiceService = {
    getServiceList(params){
        const config = {
            url: `${BASE_URL}/Get?appId=${Constants.APP_ID}`,
            params: params
        }
        return AxiosService.get(config.url,{}, config.params)
    },
    getServiceListByProviderId(params){
        const config = {
            url: `${BASE_URL}/GetbyProviderId?appId=${Constants.APP_ID}&providerId=${UserService.getUserProviderId()}`,
            params: params
        }
        return AxiosService.get(config.url,{},config.params)
    },
    getServiceListByTowTruckId(param) {
        const config = {
            url: `${BASE_URL}/GetbyTowtruckId?appId=${Constants.APP_ID}&towtruckAgentId=${param.towtruckAgentId}&page=${param.page}`,
        }
        return AxiosService.get(config.url,{})
    },
    getServiceStatusByServiceId(serviceId){
        const config = {
            url: `${BASE_URL}/Status?appId=${Constants.APP_ID}&serviceId=${serviceId}`,
        }
        return AxiosService.get(config.url,{})
    },
    getLocationRouteList(param){
        const config = {
            url: `${BASE_URL}/LocationRoute?appId=${Constants.APP_ID}&serviceId=${param.serviceId}&page=${param.page}`,
        }
        return AxiosService.get(config.url,{})
    },
    getServiceFolderByServiceId(params){
        const config = {
            url: `${BASE_URL}/Folders?appId=${Constants.APP_ID}&serviceId=${params.serviceId}&geoStatusId=${params.geoStatusId}&page=${params.page}`,
        }
        return AxiosService.get(config.url,{})
    },
    getServiceDetailById(serviceId){
        const config = {
            url: `${BASE_URL}/Detail?appId=${Constants.APP_ID}&serviceId=${serviceId}`,
        }
        return AxiosService.get(config.url,{})
    },
    postAssignTowTruckToService(param){
        const config = {
            url: `${BASE_URL}/Assign?appId=${Constants.APP_ID}`,
            data: {
                serviceId: param.serviceId,
                towTruckAgentId: param.towTruckAgentId
            }
        }
        return AxiosService.post(config.url,config.data)
    },
    postRejectService(params) {
        const config = {
            url: `${BASE_URL}/Rejection?appId=${Constants.APP_ID}`,
            data: params
        }
        return AxiosService.post(config.url,config.data)
    },
    getServiceRoute(serviceId){
        const config = {
            url: `${BASE_URL}/TripRoute?appId=${Constants.APP_ID}&serviceId=${serviceId}`,
        }
        return AxiosService.get(config.url,{})
    },
}
export default ServiceService;