import React, {
  useRef,

  useMemo, useState, useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./PlatesComponent.css";
import PlatesPageAdvanceSearch from "./PlatesPageAdvanceSearch";
import ConstantDataGridString from "../../../util/constantDataGridString";
import ConstantColorString from "../../../util/constantColorString";
import PagingUtil from "../../../util/utilityFunctions/PagingUtil";
import TowTruckService from "../../../services/TowTruckService";
import Constants from "../../../util/constantStrings";
import ErrorService from "../../../services/ErrorService";
import RoleUtil from "../../../util/utilityFunctions/RoleUtil";
export { PlatesComponent };

function PlatesComponent() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [searchParams, setSearchParams] = useState();
  const userRole = JSON.parse(localStorage.getItem("user")).role;
  const gridRef = useRef();
  const dataSource = {
    rowCount: undefined,
    getRows: (params) => {
      const payload = {
        page:PagingUtil.computedPageFromParams(params),
        ...searchParams
      }
      if(RoleUtil.isAdmin() || RoleUtil.isSupervisorAdmin()) {
        TowTruckService.getAllCarPlateList(payload).then().then(response => {
          params.successCallback(response.data.data.list, response.data.data.pages * ConstantDataGridString.DATA_GRID_PAGE_SIZE)
        }).catch(error => {
          ErrorService.errorConsole(error);
          params.failCallback();
        })
      }else {
        TowTruckService.getProviderCarPlateList(payload).then(response => {
          params.successCallback(response.data.data.list, response.data.data.pages * ConstantDataGridString.DATA_GRID_PAGE_SIZE)
        }).catch(error => {
          ErrorService.errorConsole(error);
          params.failCallback();
        })
      }
    }
  };
  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
    minWidth: 120,
    filter: true,
    icons: {
      sortAscending: '<i class="fa fa-sort-alpha-up"/>',
      sortDescending: '<i class="fa fa-sort-alpha-down"/>',
      menu: '<i class="fa fa-filter"/>',
    },
    resizable: true,
    filterParams: {
      filterOptions: null,
    },
    // floatingFilter: true,
  }));
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  function onGridReady(params) {
    params.api.setDatasource(dataSource)
  }

  function onSearch(){
    onGridReady(gridRef.current)
  }

  function getRowStyle(params){
    if (params.node.rowIndex % 2 === 0) {
      return { background: ConstantColorString.DATA_GRID_ROW_EVEN_ROW };
    }
  }

  function getDataGridColumInfo(){
    return [
      {
        headerName: "Plaka",
        field: "plateNumber",
        cellRenderer: function (params) {
          return (
              <>
                {params?.value && <div className="platesDiv">
                  <p className="plate">
                    <span>TR</span>
                    {params?.value?.toUpperCase()}
                  </p>
                </div>}
              </>
          );
        },
      },
      {
        headerName: "Tedarikçi",
        field: "partner",
        minWidth: 80,
        maxWidth: 150,
        hide: userRole !== Constants.SUPERVISOR_ADMIN,
      },
      {
        headerName: "Cihaz Id",
        field: "imei",
        hide: windowSize.innerWidth <= 530
      },
      { headerName: "Telefon", field: "mobileNumber",  hide: windowSize.innerWidth <= 530 },
    ]
  }

  function getDataGridOptions(){
    return{
      pagination: true,
      paginationPageSize: ConstantDataGridString.DATA_GRID_PAGE_SIZE,
    }
  }
  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }
  useEffect(()=>{
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[])
  return (
      <div className="card m-5 p-4">
        <div className="card-body">
        <div className="flex-container plates-container">
          <div className="ag-theme-alpine react" style={{ height: 465 }}>
            <PlatesPageAdvanceSearch searchParams={searchParams} setSearchParams={(params)=> setSearchParams(params)} onSearch={(searchParams)=> onSearch(searchParams)}/>
            <AgGridReact
              {...getDataGridOptions()}
              rowClass={"grid-row"}
              getRowStyle={getRowStyle}
              ref={gridRef}
              rowModelType="infinite"
              columnDefs={getDataGridColumInfo()}
              cacheBlockSize={ConstantDataGridString.DATA_GRID_PAGE_SIZE}
              cacheOverflowSize={0}
              defaultColDef={defaultColDef}
              rowHeight={ConstantDataGridString.DATA_GRID_ROW_HEIGHT}
              onGridReady={onGridReady}
            />
          </div>
        </div>
        </div>
    </div>
  );
}
