import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { authAtom } from "state";
import { useUserActions } from "_actions";
import axaLogo from "../../../assets/axa/axa.png";
import { useState } from "react";
import "./Nav.css";
import Constants from "../../../util/constantStrings";
export { Nav };

function Nav() {
  const auth = useRecoilValue(authAtom);
  const userActions = useUserActions();
  const [state, setState] = useState(true);
  // only show nav when logged in
  if (!auth) return null;
  const user = JSON.parse(localStorage.getItem("user"));

  return (
      <>
        {user &&
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
            <Link to="/" className="navbar-brand">
              <img className="logo" src={axaLogo} alt="" />
            </Link>
            <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ backgroundColor: "transparent" }}

                onClick={() => {
                  setState(prevState => !prevState);
                }}
            >
              <span className="navbar-toggler-icon"/>
            </button>
            <div
                className={
                  state
                      ? "collapse navbar-collapse"
                      : "collapse navbar-collapse active navbar-active"
                }
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <div className="nav-item-container" onClick={() => {
                    setState(prevState => !prevState);
                  }}>
                    <Link to="/" className="nav-link auth-logo nav-page-item">
                      <i className=" ri-home-3-line align-middle me-1 nav-item-icon"> </i>
                      <span className="nav-item-text">Anasayfa</span>
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-item-container" onClick={() => {
                    setState(prevState => !prevState);
                  }}>
                    <Link to="/services" className="nav-link auth-logo nav-page-item">
                      <i className="ri-task-line z-middle me-1 nav-item-icon"> </i>
                      <span className="nav-item-text">Servisler</span>
                    </Link>
                  </div>

                </li>
                <li className="nav-item">
                  <div className="nav-item-container" onClick={() => {
                    setState(prevState => !prevState);
                  }}>
                    <Link to="/plates" className="nav-link auth-logo nav-page-item">
                      <i className="ri-car-line align-middle me-1 nav-item-icon"> </i>
                      <span className="nav-item-text">Plakalar</span>
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-item-container" onClick={() => {
                    setState(prevState => !prevState);
                  }}>
                    {(user.role === Constants.PROVIDER || user.role === Constants.SUPERVISOR_ADMIN) &&
                        <Link to="/map" className="nav-link auth-logo nav-page-item">
                          <i className="ri-map-pin-2-line align-middle me-1 nav-item-icon"> </i>
                          <span className="nav-item-text">Harita</span>
                        </Link>
                    }
                  </div>
                </li>
                  {(user.role === Constants.SUPERVISOR_ADMIN && user.role === Constants.PROVIDER) && <li className="nav-item btn-ghost-secondary rounded light-dark-mode shadow-none">
                    <div className="nav-item-container" onClick={() => {
                      setState(prevState => !prevState);
                    }}>
                    <Link
                        to="/identifyPlate"
                        className="nav-link auth-logo nav-page-item"
                    >
                      <i className="ri-logout-box-r-line align-middle me-1 nav-item-icon"> </i>
                      <span className="nav-item-text">Plaka Tanımla</span>
                    </Link>
                    </div>
                  </li>}
                <li className="nav-item">
                  <div className="nav-item-container" onClick={userActions.logout}>
                    <Link
                        to="/account/login"
                        className="nav-link auth-logo nav-page-item"
                    >
                      <i className="ri-logout-box-r-line align-middle me-1 nav-item-icon"> </i>
                      <span className="nav-item-text">Çıkış Yap</span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
              </div>
        </nav>}
      </>

  );
}
