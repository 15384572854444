import {LoadScript} from "@react-google-maps/api";
import {GoogleMaps} from "../../../_helpers";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Util from "../../../util/utilityFunctions/Util";
import TowTruckService from "../../../services/TowTruckService";
import Constants from "../../../util/constantStrings";
import ErrorService from "../../../services/ErrorService";
import CustomerService from "../../../services/CustomerService";
import ConstantAlertMessages from "../../../util/constantAlertMessages";
import Alert from "../../../_helpers/Alerts";

function CustomerAndTowTruckLocationComponent() {
    let { guid } = useParams();
    const API_KEY = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
    const [isLoading, setIsLoading] = useState(true);
    const [towTruckLocation, setTowTruckLocation] = useState();
    const [customerLocation, setCustomerLocation] = useState();
    const [locationInfoList, setLocationInfoList] = useState([]);
    const [isShowAlert, setIsShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    function setAlertMessageParams(message) {
        setAlertMessage(message);
        setIsShowAlert(true);
    }
    function setLocationInfo() {
        const tempArr = [towTruckLocation, customerLocation];
        setLocationInfoList(tempArr);
    }
    function getTowTruckLocationInfo() {
        TowTruckService.getTowTruckLocationInfoByGuid(guid).then(response => {
            if (response.data.responseStatus === Constants.RESPONSE_STATUS_SUCCESS_STRING) {
                if(!Util.checkIsNullOrUndefined(response.data.data.latitude) && !Util.checkIsNullOrUndefined(response.data.data.longitude)) {
                    const locationInfo = {
                        lat: Number(response.data.data.latitude),
                        lng: Number(response.data.data.longitude),
                        plateNumber: response.data.data.plateNumber,
                        statusId: 2
                    }
                    setTowTruckLocation(locationInfo);
                    getCustomerLocationInfo();
                } else {
                    setAlertMessageParams(ConstantAlertMessages.TOW_TRUCK_LOCATION_NOT_FOUND);
                }
            } else if (response.data.responseStatus === Constants.RESPONSE_STATUS_ERROR_STRING) {
                setAlertMessageParams(response.data.message);
            }
        }).catch(error => ErrorService.errorConsole(error))
    }
    function getCustomerLocationInfo() {
        CustomerService.getCustomerLocationByServiceGuid(guid).then(response => {
            if (response.data.responseStatus === Constants.RESPONSE_STATUS_SUCCESS_STRING) {
                if(!Util.checkIsNullOrUndefined(response.data.data.customerInfo.geoCustLatitude) && !Util.checkIsNullOrUndefined(response.data.data.customerInfo.geoCustLongitude)) {
                    const locationInfo = {
                        lat: Number(response.data.data.customerInfo.geoCustLatitude),
                        lng: Number(response.data.data.customerInfo.geoCustLongitude),
                        statusId: 3
                    }
                    setCustomerLocation(locationInfo);
                    setLocationInfo();
                } else {
                    setAlertMessageParams(ConstantAlertMessages.CUSTOMER_LOCATION_NOT_FOUND);
                }
            } else if (response.data.responseStatus === Constants.RESPONSE_STATUS_ERROR_STRING) {
                setAlertMessageParams(response.data.message);
            }
        })
    }
    useEffect(()=> {
        setInterval(() => {
            if(!Util.checkIsNullOrUndefined(guid) && !Util.checkIsNullOrUndefined(guid)) {
                getTowTruckLocationInfo();
            }
        }, Constants.REFRESH_TIME_GET_TOW_TRUCK_LOCATION)
    },[])
    return (
        <div className="p-4">
            <div className="container">
                {isShowAlert ? (Alert.infoAlert(alertMessage)) :
                    <LoadScript id={"google-map-script"} googleMapsApiKey={API_KEY}>
                        <GoogleMaps
                            showTruckLocation={false}
                            towTruckLocationList={locationInfoList}
                            isLoading={isLoading}
                            setIsLoading={(state) => setIsLoading(state)}
                            showDistanceMatrix={false}
                        />
                    </LoadScript>}
            </div>
        </div>

    )
}
export default CustomerAndTowTruckLocationComponent;