import React,{ useState } from "react";
import { CompleteServicesComponent } from "../complete-services/CompleteServicesComponent";
import { IncompleteServicesComponent } from "../incomplete-services/IncompleteServicesComponent";
import "./ServicesComponent.css";


function ServicesComponent() {
  const [serviceTab, setServiceTab] = useState(true)
  return (
      <div className="card m-5 p-4">
              <div className="card-header">
                  <div className="services-component-right-side">
                      <ul className="nav nav-pills services-buttons">
                          <li
                    id={"incomplated-service"}
                    className={serviceTab ? "nav-item nav-link services-buttons active" : "nav-item nav-link services-buttons"}
                    onClick={() => setServiceTab( true)}
                          >
                  Tamamlanmamış Servisler
                          </li>
                          <li
                    id={"complete-service"}
                    className={serviceTab ? "nav-item nav-link services-buttons" : "nav-item nav-link services-buttons active"}
                    onClick={() => setServiceTab(false)}
                    style={{ marginRight: "10px" }}
                          >
                  Tamamlanmış Servisler
                          </li>
              </ul>
                  </div>
              </div>
              <div className="card-body">
                  {serviceTab ? (
                      <IncompleteServicesComponent/>
                  ) : (
                      <CompleteServicesComponent/>
                  )}
        </div>
          </div>
  );
}

export { ServicesComponent };
