import Util from "./Util";
import Constants  from "util/constantStrings";
import UserService from "../../services/UserService";
const checkHasAnyRole = (value) => {
    if (!Util.checkIsNullOrUndefined(value) && !Util.checkIsEmptyString(value)) {
        return true;
    } else return false;
}
const isAdmin = () => {
    const role = UserService.getUser().role
    return role === Constants.ADMIN
}
const isSupervisorAdmin = () => {
    const role = UserService.getUser().role
    return role === Constants.SUPERVISOR_ADMIN
}
export default {checkHasAnyRole, isAdmin, isSupervisorAdmin}