import {atom} from "recoil";
const ServicesAtom = atom({
  ket:"services",
  default: [],
})

const IncompleteServicesAtom = atom({
  key: "incompleteServices", // unique ID (with respect to other atoms/selectors)
  default: [],
});

const CompleteServicesAtom = atom({
  key: "completeServices", // unique ID (with respect to other atoms/selectors)
  default: [],
});

const PlateAtom = atom({
  key: "plateServices", // unique ID (with respect to other atoms/selectors)
  default: [],
});

const CurrentServicesAtom = atom({
  key: "currentServices", // unique ID (with respect to other atoms/selectors)
  default: [],
});

const ServiceStatusInfoAtom = atom ({
  key: "serviceStatusInfo",
  default: []
});

const ServiceFolderListAtom = atom({
  key: "serviceFolderList",
  default: []
})
const ServiceDetailAtom = atom({
  key: "serviceDetail",
  default: {}
})

export {
  IncompleteServicesAtom,
  CompleteServicesAtom,
  PlateAtom,
  CurrentServicesAtom,
  ServiceStatusInfoAtom,
  ServiceFolderListAtom,
  ServiceDetailAtom,
  ServicesAtom
};
