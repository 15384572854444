class ConstantColorString {
    static DATA_GRID_ROW_EVEN_ROW = "#F0F0F0";
    static DATA_GRID_STATUS_WAITING = "#FFBC11";
    static DATA_GRID_STATUS_ARRIVED_LOCALE = "#027180";
    static DATA_GRID_STATUS_FINISHED = "#027180";
    static DATA_GRID_STATUS_ON_WAY = "#0095FF";
    static DATA_GRID_STATUS_HAS_SERVICE = "#00A40E";
    static GRID_COLOR_MAP = ConstantColorString.buildColorMap();
    static buildColorMap(){
        const colorMap = new Map();
        colorMap.set( 0, this.DATA_GRID_STATUS_WAITING);
        colorMap.set( 1, this.DATA_GRID_STATUS_HAS_SERVICE);
        colorMap.set( 2, this.DATA_GRID_STATUS_ON_WAY);
        colorMap.set( 3, this.DATA_GRID_STATUS_ARRIVED_LOCALE);
        colorMap.set( 4, this.DATA_GRID_STATUS_FINISHED);
        return colorMap;
    }
}
export default ConstantColorString;