import React from "react";
import "../../../index.css";
import {useFormik} from "formik";
import Constants from "../../../util/constantStrings";

function PlatesPageAdvanceSearch({searchParams, setSearchParams, onSearch}) {
    const userRole = JSON.parse(localStorage.getItem("user")).role;
    const formik = useFormik({
        initialValues: {
            plate: "",
            imei: "",
            partner: ""
        },
        onSubmit: () => {
            onSearch(searchParams)
        },
        onReset: values => {
            const searchParams = {
                plate: values.plate,
                imei: values.imei,
                partner: values.partner
            }
            setSearchParams(searchParams)
            onSearch()
        }
    });
  return (
    <div className="container advance-search-container">
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <input
                id="plate"
                name="plate"
                type="text"
                placeholder="Plaka"
                className="advance-search-input"
                onChange={(event) => {
                    setSearchParams({
                        ...searchParams,
                        plate: event.target.value
                    })
                }}
                value={searchParams?.plate}
            />
            {userRole === Constants.SUPERVISOR_ADMIN &&
                <input
                    id="partner"
                    name="partner"
                    type="text"
                    placeholder="Tedarikçi"
                    className="advance-search-input"
                    onChange={(event) => {
                        setSearchParams({
                            ...searchParams,
                            partner: event.target.value
                        })
                    }}
                    value={searchParams?.partner}
                />
            }
            <input
                id="imei"
                name="imei"
                type="text"
                placeholder="Cihaz Id"
                className="advance-search-input"
                onChange={(event) => {
                    setSearchParams({
                        ...searchParams,
                        imei: event.target.value
                    })
                }}
                value={searchParams?.imei}
            />
            <button className="advance-search-filter-button" type="submit">Ara</button>
            <button className="advance-search-reset-button" type="reset">Temizle</button>
        </form>
    </div>
  );
}

export default PlatesPageAdvanceSearch;
