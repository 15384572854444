import Constants from "../util/constantStrings";

export const setUser = (user) => {
    window.localStorage.setItem(Constants.USER, JSON.stringify(user));
}
export const getUser = () => {
    return JSON.parse(window.localStorage.getItem(Constants.USER));
}
export const destroyUser = () => {
    window.localStorage.removeItem(Constants.USER);
}
export const getUserProviderId = () => {
    return JSON.parse(window.localStorage.getItem(Constants.USER))?.providerId;
}

export default {setUser, getUser, destroyUser, getUserProviderId}