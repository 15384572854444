export const errorToast = (message) => {
    return (
        <div style={{zIndex: "1"}}>
            <div id="errorToast" className="toast toast-border-danger mt-3 fade show toast-message-box" role="alert" data-bs-autohide="false" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                            <i className="ri-alert-line align-middle"/>
                        </div>
                        <div className="flex-grow-1">
                            <h6 className="mb-0">{message}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export const successToast = (message) => {
    return (
        <div>
            <div id="successToast" className="toast toast-border-success mt-3 fade show toast-message-box" role="alert" data-bs-autohide="false" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-2">
                            <i className="ri-alert-line align-middle"/>
                        </div>
                        <div className="flex-grow-1">
                            <h6 className="mb-0">{message}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
